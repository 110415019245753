import { graphql } from "gatsby"
import React from "react"
import Layout from "../../layouts/Layout"
import SiteMetadata from "../../components/SiteMetadata"

const TermsOfServicePage = ({ data }) => (
  <Layout>
    <SiteMetadata title="Terms of Service" description="Sample description" />

    <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
      <div className="flex flex-wrap">
          <div className="w-full pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Terms of Service
            </h1>

            <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
              Respect is a two-way street
            </h2>
            <br />
            <div className="mt-4 leading-loose">
              <h1 className="text-xl leading-tight font-semibold tracking-tight sm:text-2xl">Accepting the Terms</h1>
              <p className="mt-2">
              These Terms of Service (“Terms”) are a legal agreement between Blue Boat, its Affiliates (“we”) and you (“you”). Affiliates shall mean subsidiaries, parent companies, joint ventures and other corporate entities under common ownership. By using or accessing any Blue Boat game or application (“Service”) you agree to be bound by these Terms. Blue Boat’s Privacy Policy is incorporated herein by reference. By accessing or using the Service, you agree that you have read, understood, and accept to be bound by the Terms. Blue Boat reserves the right, in its sole discretion, to modify or revise these Terms at any time, and you agree to be bound by such modifications or revisions. If you do not agree to the Terms, do not use the Service.
              </p>
              <p className="mt-2">Users are responsible for periodically viewing the Terms. Your continued use of the Service after a change or update has been made will constitute your acceptance to the revised Terms. If you do not agree to the Terms your only remedy is to discontinue your use of the Service and cancel any accounts you have made using the Service.</p>
              <p className="mt-2">If you violate the Terms, Blue Boat reserves the right to issue you a warning regarding the violation or immediately terminate or suspend any or all accounts you have created using the Service. You agree that Blue Boat need not provide you notice before terminating or suspending your account(s), but it may do so.</p>
              <p className="mt-2">Blue Boat reserves the right to refuse any user access to the Services without notice for any reason, including, but not limited to, a violation of the Terms.</p>
              <p className="mt-2">You agree that Blue Boat may discontinue the Service or change the content of the Service at any time, for any reason, with or without notice to you, without liability.</p>
              <p className="mt-2">You represent that you are 13 years old or older. If you are between the ages of 13 and 18, you represent that your legal guardian has reviewed and agrees to the Terms.</p>
              <h1 className="text-xl leading-tight font-semibold tracking-tight sm:text-2xl mt-8">Intellectual Property/Ownership</h1>
              <p className="mt-2">
              All materials that are part of the Service (including, but not limited to, designs, text, graphics, pictures, video, information, applications, software, music, sound and other files, and their selection and arrangement) are protected by law from unauthorized use. The entire contents of the Service are copyrighted under the Thailand copyright laws and/or similar laws of other jurisdictions.
              </p>
              <p className="mt-2">You agree that no materials that are part of the Service may be modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, or otherwise exploited without Funplusgame’s express prior written permission, except that the foregoing does not apply to your own User Content (as defined below) that you legally post on the Site. All other uses of copyrighted material, including any derivative use, require express prior written permission from Funplusgame. Any reproduction or redistribution of materials not in accordance with these Terms is expressly prohibited and may result in severe civil and criminal penalties.</p>
              <p className="mt-2">Blue Boat and/or its licensors remain the owners of right, title, and interest, including copyrights and other intellectual property rights, in and to all materials posted on the Services by Blue Boat. You acknowledge that you do not acquire any ownership rights by using the Service or by accessing any materials posted on the Service by Blue Boat, or any derivative works thereof.</p>
              <p className="mt-2">Blue Boat grants you a personal, non-exclusive, non-transferable, revocable, limited scope license to use the Service solely for the purpose of viewing and using the applicable Services and for no other purpose whatsoever. Your license to use the Services is limited by these Terms.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default TermsOfServicePage